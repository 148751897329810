<!--
    有三种数据源类型有[数据库, CSV文件, API]
      数据库:
        1、可输入SQL语句，点击【运行查询】，在【运行结果框】中显示查询结果
        2、也可直接在【查看数据表】中选择数据表，选择数据表后定位到【数据预览框】，【点击预览数据】出现结果。
      CSV
        1、当类型选择为“CSV文件”时，不能输入SQL语句和运行查询
        2、选择添加的数据源后，定位到【数据预览框】，【点击预览数据】出现结果；
      API
        1、当类型选择为“API”时，在右侧【SQL编辑框】内输入SQL语句，点击【运行查询】，在【运行结果框】中显示查询结果。
    保存查询：
      1、点击【保存查询】弹出【保存查询框】
      2、输入名称（必填）和描述（非必填，要求小于100字）可保存【当前SQL编辑框中输入的SQL语句】。
    创建图表
      1、当当前查询已保存时可直接跳转到【图表编辑页】，根据该SQL模型制作图表；
      2、当查询未保存时弹出【提示框】显示无法创建图表，点击【保存查询】文字弹出【保存查询框】。
 -->
<template>
  <div class="sql_editer">
    <div class="header margin-bottom-20">
      <section-title title="SQL编辑器"></section-title>
    </div>
    <div class="main">
      <!-- 选项 -->
      <vue-scroll>
        <div class="main_wrapper">
          <div class="left_box">
            <h1 class="text_color_active text_size_14 text_line_height_20 margin-bottom-20">数据源</h1>
            <!-- 选择数据类型 -->
            <div class="form_item margin-bottom-18">
              <p class="text_color_primary text_size_14 text_line_height_30">数据源类型</p>
              <el-select size="small" v-model="dataSourceType" placeholder="请选择数据源类型" @change="onChangeDataSourceType">
                <el-option v-for="item in dataSourceTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

            <!-- 选择数据源 -->
            <div :class="['form_item', isCSV || dataSourceType === '' ? 'margin-bottom-18' : 'margin-bottom-30']">
              <p class="text_color_primary text_size_14 text_line_height_30">选择已添加的数据源</p>
              <el-select :loading="dataSourceSelectLoading" size="small" v-model="dataSourceId" placeholder="请选择已添加的数据源" @change="onChangeDataSourceId">
                <el-option v-for="item in dataSourceList" :key="item.id" :label="item.title" :value="item.id"></el-option>
              </el-select>
            </div>

            <!-- <el-button size="small" style="margin-bottom: 30px;" @click="onPreview" v-show="isCSV" :disabled="!isCSV || dataSourceType === ''">
              预览数据
            </el-button> -->

            <div class="split_line margin-bottom-10"></div>

            <!-- 选择数据表 可以预览数据 只有数据源类型为数据库时可用 -->
            <div class="form_item margin-bottom-18" v-show="dataSourceId">
              <p class="text_color_primary text_size_14 text_line_height_30">预览数据表</p>
              <el-select :loading="dataTableSelectLoading" size="small" v-model="dataTableName" placeholder="请选择数据表进行预览" @change="onChangeDataTableName">
                <el-option v-for="item in dataTableList" :key="item.datatable" :label="item.datatable" :value="item.datatable"></el-option>
              </el-select>
            </div>
            <el-button v-show="dataSourceId" size="small" style="margin-bottom: 20px;" @click="onPreview" :disabled="dataSourceType === '' || dataTableName === ''">
              预览数据
            </el-button>

            <!-- SQL输入框 -->
            <div class="form_item margin-bottom-18">
              <p class="text_color_primary text_size_14 text_line_height_30">SQL</p>
              <!-- 代码编辑器 -->
              <code-editor :codes.sync="codes" language="sql" editorHeight="266px" @onEditorMounted="onEditorMounted"></code-editor>
            </div>
            <el-button size="small" class="margin-bottom-20" :disabled="dataSourceType === '' || dataSourceId === '' || codes.trim() === ''" @click="runSql">
              运行查询
            </el-button>
          </div>

          <!-- 结果展示 -->
          <div class="right_box">
            <el-tabs class="my-el-tabs" v-model="activeTabName" type="border-card">
              <el-tab-pane name="result" label="运行结果">
                <div class="pane_wrapper">
                  <div class="pane_header flex margin-bottom-20">
                    <el-button type="primary" :loading="saveBtnLoading" size="small" @click="openSaveQueryDialog">保存查询</el-button>
                    <el-button size="small" @click="createChart">制作图表</el-button>
                  </div>
                  <div class="pane_main result">
                    <vue-scroll>
                      <div style="position:absolute;min-width:100%;width: -webkit-fit-content;">
                        <el-table :data="runResultData.allData" v-loading="runResultTableLoading" element-loading-background="rgba(24,34,45,0.9)"
                          class="my-el-table no-td-border" cell-class-name="my-el-table-cell" stripe>
                          <el-table-column v-for="item in runResultData.columns" :key="item" :prop="item" :label="item"></el-table-column>
                        </el-table>
                      </div>
                    </vue-scroll>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="preview" label="数据预览">
                <div class="pane_wrapper">
                  <div class="pane_main preview">
                    <vue-scroll>
                      <div style="position:absolute;min-width:100%;width: -webkit-fit-content;">
                        <el-table :data="previewObj.allData" empty-text="点击“预览数据”按钮，预览数据" v-loading="previewTableLoading"
                          element-loading-background="rgba(24,34,45,0.9)" class="my-el-table no-td-border" cell-class-name="my-el-table-cell" stripe>
                          <el-table-column v-for="item in previewObj.columns" :key="item" :prop="item" :label="item"></el-table-column>
                        </el-table>
                      </div>
                    </vue-scroll>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </vue-scroll>
    </div>

    <!-- 保存查询的Dialog -->
    <save-query
      :visible.sync="saveQueryDialogVisible"
      :data-source-id="dataSourceId"
      :sql-id="sqlId"
      :saved-obj="savedQueryObj"
      :sql-code="codes"
      @callback="setQueryData">
    </save-query>
  </div>
</template>

<script>
import * as api from '~/api'

import codeEditor from '~/components/code-editor'
import saveQuery from './components/save-query'
export default {
  data () {
    return {
      saveBtnLoading: false, // 保存查询loading
      runResultTableLoading: false, // 运行结果表Loading
      previewTableLoading: false, // 数据预览表Loading
      dataSourceSelectLoading: false, // 数据源选择器Loading
      dataTableSelectLoading: false, // 数据表选择器Loading
      saveQueryDialogVisible: false, // 保存查询的Dialog
      dataSourceType: '', // 数据源类型
      dataSourceId: '', // 数据源id
      dataTableName: '', // 数据表表名
      activeTabName: 'result', // 当前激活的tabName
      monaco: null, // 编辑器创建成功后返回的对象
      codes: '', // 代码内容
      dataSourceTypeList: [
        { value: '1', label: '数据库' },
        { value: '2', label: 'CSV文件' },
        { value: '3', label: 'API' }
      ], // 数据源类型列表
      dataSourceList: [], // 数据源列表
      dataTableList: [], // 数据表列表
      runResultData: {
        allData: [],
        columns: []
      }, // 运行结果
      previewObj: {
        allData: [],
        columns: []
      }, // 预览表
      savedQueryObj: {
        id: '',
        title: '',
        description: '',
        dataSourceType: '',
        dataSourceId: '',
        sql: ''
      } // 所保存的查询内容
    }
  },
  components: {
    codeEditor,
    saveQuery
  },
  computed: {
    // 项目id
    projectId () {
      return this.$route.query.projectId || ''
    },
    projectName () {
      return this.$route.query.projectName || ''
    },
    sqlId () {
      return this.$route.query.sqlId || ''
    },
    // 数据源是数据库类型
    isDatabase () {
      return this.dataSourceType === '1'
    },
    // 数据源是CSV文件类型
    isCSV () {
      return this.dataSourceType === '2'
    },
    // 数据源是API类型
    isAPI () {
      return this.dataSourceType === '3'
    }
  },
  methods: {
    // 编辑器初始化成功
    onEditorMounted (monaco) {
      this.monaco = monaco
      if (this.sqlId !== '') {
        this.getSavedSqlDetail()
      }
    },
    // 获取保存的sql查询详情
    getSavedSqlDetail () {
      api.getSavedQueryDetail({
        sqlId: this.sqlId
      }).then(async res => {
        if (res.data.code === 0) {
          const item = res.data.data
          // 赋值savedQuertObj
          for (const key in this.savedQueryObj) {
            if (Object.prototype.hasOwnProperty.call(this.savedQueryObj, key)) {
              this.savedQueryObj[key] = item[key]
            }
          }
          // 赋值相关表单
          // 赋值相关表单
          this.dataSourceType = item.dataSourceType
          // this.codes = item.sql
          this.monaco.setValue(item.sql)
          await this.onChangeDataSourceType(this.dataSourceType)
          this.dataSourceId = item.dataSourceId
          this.onChangeDataSourceId(this.dataSourceId)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('网络错误！')
      })
    },
    // 数据源类型发生变化 1-数据库， 2-csv文件， 3-api
    async onChangeDataSourceType (value) {
      if (value === '1') {
        this.monaco.updateOptions({
          readOnly: false
        })
      } else if (value === '2') {
        // csv文件则不能使用SQL编辑器
        this.monaco.updateOptions({
          readOnly: true
        })
      } else if (value === '3') {
        this.monaco.updateOptions({
          readOnly: false
        })
      }
      // 清空数据源及数据表选中项
      this.dataSourceId = ''
      this.dataTableName = ''
      // 获取数据源
      await this.getDataSourceList()
    },
    /**
     * 根据所选择的数据类型获取数据源
     */
    async getDataSourceList () {
      this.dataSourceSelectLoading = true
      await api.getDataSourceList({
        // 项目id
        spaceId: this.projectId,
        // 数据源类型
        dataSourceType: this.dataSourceType
      }).then(res => {
        if (res.data.code === 0) {
          // console.log('数据源列表', res.data.data)
          this.dataSourceList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
        this.dataSourceSelectLoading = false
      }).catch(err => {
        this.dataSourceSelectLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 数据源发生变化
    onChangeDataSourceId (value) {
      // if (this.isDatabase) {
      //   // 获取数据表
      //   this.getDatabaseTableList()

      // // 如果是CSV或API切换数据源的话 直接切到 "数据预览框" 用户点击预览数据时获取预览的数据
      // } else if (this.isCSV) {
      //   // 切换到预览框
      //   this.setActiveTabName('preview')
      // }
      // 清空数据表选中项
      this.dataTableName = ''

      this.getDatabaseTableList()
    },
    /**
     * 只有在数据类型未 "数据库" 的情况下
     * 数据源是数据库列表
     * 根据所选数据源(数据库)id来获取此数据库下的数据表列表
     */
    getDatabaseTableList () {
      // 如果数据源不是数据库的话则不获取
      // if (this.dataSourceType !== '1') return

      this.dataTableSelectLoading = true
      api.getDatabaseTableList({
        // 数据库id
        dataSourceId: this.dataSourceId
      }).then(res => {
        if (res.data.code === 0) {
          // console.log('数据库中数据表列表', res.data.data)
          this.dataTableList = res.data.data

          if (this.dataSourceType === '2' || this.dataSourceType === '3') {
            this.dataTableName = this.dataTableList[0].datatable
            this.onChangeDataTableName()
          } else {
            this.dataTableName = ''
          }
        } else {
          this.$message.error(res.data.message)
        }
        this.dataTableSelectLoading = false
      }).catch(err => {
        this.dataTableSelectLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 数据表发生变化
    onChangeDataTableName () {
      // 数据表变化只有是数据库类型时切换
      // if (!this.isDatabase) return

      this.setActiveTabName('preview')
    },

    // 运行查询
    runSql () {
      if (!this.codes.trim()) {
        this.$message.warning('SQL语句不能为空')
        return
      }
      // 切换到结果框
      this.setActiveTabName('result')

      this.getExecuteSqlResult()
    },
    // 传入SQL,获取执行结果
    getExecuteSqlResult () {
      this.runResultTableLoading = true
      api.getExecuteSqlResult({
        // 数据源id
        dataSourceId: this.dataSourceId,
        // 待执行SQL语句
        sql: this.codes
      }).then(res => {
        if (res.data.code === 0) {
          // console.log('执行SQL的结果', res.data.data)
          this.runResultData = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
        this.runResultTableLoading = false
      }).catch(err => {
        this.runResultTableLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 预览数据
    onPreview () {
      this.setActiveTabName('preview')
      // 预览数据
      this.getPreviewData()
    },
    // 获取数据源的预览数据
    getPreviewData () {
      this.previewTableLoading = true
      api.getPreviewData({
        // 数据源id
        dataSourceId: this.dataSourceId,
        // 如果是数据源类型是数据库的话则传数据表表名
        tableName: this.dataSourceType === '1' ? this.dataTableName : ''
      }).then(res => {
        if (res.data.code === 0) {
          // console.log('预览结果', res.data.data)
          this.previewObj = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
        this.previewTableLoading = false
      }).catch(err => {
        this.previewTableLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 设置tabName
    setActiveTabName (tabName) {
      this.activeTabName = tabName
    },

    // 保存编辑
    editQuery () {
      this.saveBtnLoading = true
      api.editSavedQueryItem({
        sqlId: this.sqlId,
        sql: this.codes,
        dataSourceId: this.dataSourceId
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('编辑查询成功')
          const data = res.data.data
          this.savedQueryObj.id = data.id
          this.savedQueryObj.title = data.title
          this.savedQueryObj.description = data.featureData.description
          this.savedQueryObj.dataSourceType = data.featureData.dataSourceType
          this.savedQueryObj.dataSourceId = data.featureData.dataSourceId
          this.savedQueryObj.sql = data.featureData.sql
        } else {
          this.$message.error(res.data.message)
        }
        this.saveBtnLoading = false
      }).catch(err => {
        this.saveBtnLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 打开保存查询Dialog
    openSaveQueryDialog () {
      if (this.isCSV) {
        // 数据源类型为CSV,没有sql查询
        return
      }
      if (this.dataSourceId === '' || this.codes.trim() === '') {
        this.$message.warning('请先选择数据源并编写SQL代码')
        return
      }
      if (this.sqlId !== '') {
        // 编辑查询
        this.editQuery()
      } else {
        // 创建查询
        this.saveQueryDialogVisible = true
      }
    },
    /**
     * 创建图表
     *  创建图表需要先对比现有的
     *    dataSourceType、dataSourceId、sql
     *  是否和之前保存的相同，如若不相同 则提示未进行保存
     *  如果相同，则带着已保存查询的Id去图表制作页面
     */
    // TODO
    createChart () {
      if (this.isCSV) {
        // 选择的数据源类型是CSV
        if (this.dataSourceId !== '') {
          this.$router.push({
            path: '/chart-detail',
            query: {
              mode: 'create',
              projectId: this.projectId,
              projectName: this.projectName,
              sidebar: 'sql-editor',
              dataSourceType: this.dataSourceType,
              dataSourceId: this.dataSourceId
            }
          })
        } else {
          this.$message.warning('请先选择数据源')
        }
      } else {
        const bol1 = this.dataSourceType === this.savedQueryObj.dataSourceType && this.dataSourceType !== ''
        const bol2 = this.dataSourceId === this.savedQueryObj.dataSourceId && this.dataSourceId !== ''
        const bol3 = this.codes.trim() === this.savedQueryObj.sql.trim() && this.codes.trim() !== ''
        // 全真则通过
        if (bol1 && bol2 && bol3) {
          // console.log('SQL查询模型已经保存，进行跳转！')
          this.$router.push({
            path: '/chart-detail',
            query: {
              mode: 'create',
              projectId: this.projectId,
              projectName: this.projectName,
              sidebar: 'sql-editor',
              dataSourceType: this.dataSourceType,
              dataSourceId: this.dataSourceId,
              sqlId: this.savedQueryObj.id
            }
          })
        } else {
          this.$alert('当前SQL查询模型未保存，无法创建图表！', 'SQ查询未保存', {
            confirmButtonText: '确定',
            callback: action => {
              // 打开保存查询的Dialog
              this.openSaveQueryDialog()
            }
          })
        }
      }
    },
    // 设置已保存的查询数据
    setQueryData (data) {
      console.log(data)
      this.savedQueryObj = {
        ...data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .sql_editer {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    .main {
      flex: 1;
      &_wrapper {
        position: absolute;
        min-width: 100%;
        height: 793px;
        display: flex;
      }
      .left_box {
        // width: 31.65%;
        width: 500px;
        height:793px;
        background-color:#18222D;
        border-radius:4px;
        margin-right: 20px;
        padding: 20px;
        .sql_wrapper {
          width: 100%;
          height:266px;
          background:rgba(3,13,23,1);
          border:1px solid rgba(96,106,117,1);
          opacity:1;
          border-radius:2px;
        }
      }
      .right_box {
        flex: 1;
        // width: 67.09%;
        height:793px;
        background-color:#18222D;
        border-radius:4px;
        overflow: hidden;
        .pane_wrapper {
          width: 100%;
          padding: 15px 5px;
          .pane_main {
            width: 100%;
            background:rgba(24,34,45,1);
            border:1px solid rgba(96,106,117,1);
            border-radius:2px;
            &.result {
              height: 640px;
            }
            &.preview {
              height: 688px;
            }
          }
        }
      }
    }

    .split_line {
      width: 100%;
      height:1px;
      background-color:#606A75;
    }
  }
</style>
