<template>
  <el-dialog
    width="600px"
    :visible.sync="$_visible"
    @close="closeDialog">
    <p slot="title" class="text_size_16 text_color_white text_weight_bold">保存查询</p>
    <el-form
      class="form padding-left-20 padding-right-20"
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-position="left"
      label-width="160px"
      hide-required-asterisk>
      <el-form-item label="名称" prop="title">
        <el-input type="text" v-model="form.title" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="form.description">
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button size="small" @click="closeDialog">取消</el-button>
      <el-button type="primary" :loading="saveBtnLoading" size="small" @click="saveForm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import * as api from '~/api'
export default {
  data () {
    return {
      // 确定按钮loading
      saveBtnLoading: false,
      // 表单对象
      form: {
        title: '',
        description: ''
      },
      // 添加目标表达那对象验证
      rules: {
        title: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    // 数据源ID
    dataSourceId: {
      type: String,
      default: ''
    },
    // sql语句
    sqlCode: {
      type: String,
      default: ''
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    // 项目id
    projectId () {
      return this.$route.query.projectId || ''
    }
  },
  methods: {
    // 保存查询
    saveQuery (result) {
      this.saveBtnLoading = true
      api.saveQuery({
        spaceId: this.projectId,
        ...this.form,
        sql: this.sqlCode,
        dataSourceId: this.dataSourceId
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('保存查询成功')
          const data = res.data.data
          this.$emit('callback', {
            id: data.id,
            title: data.title,
            description: data.featureData.description,
            dataSourceType: data.featureData.dataSourceType,
            dataSourceId: data.featureData.dataSourceId,
            sql: data.featureData.sql
          })
          this.closeDialog()
        } else {
          this.$message.error(res.data.message)
        }
        this.saveBtnLoading = false
      }).catch(err => {
        this.saveBtnLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 保存提交
    saveForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveQuery()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 关闭添加表单的dialog
    closeDialog () {
      this.$refs.form.resetFields()
      this.$_visible = false
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
